/* 隐藏滚动条 */
/*::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/
/* 隐藏滚动条 */
.scrollable {
    scrollbar-width: none; /* Firefox */
}
.background{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}
.container{
    width:450px;
    min-height:210px;
    background-color: white;
}
.topBar{
    height:44px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.topTitle{
    height:100%;
    margin-left:20px;
    display: flex;
    align-items: center;
    font-family: '微软雅黑 Bold', '微软雅黑', '微软雅黑', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    color: #666666;
}
.topCancelButton{
    height:100%;
    margin-right:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'FontAwesome', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #999999;
    cursor: pointer;
}
.middleContainer{
    margin-top:14px;
    width:100%;
    min-height:90px;
    display: flex;
    flex-direction: row;
}
.questionIcon{
    margin-left:30px;
    width:30px;
    height:30px;
    color: #FE9400;
}
.middleRightContainer{
    margin-left:10px;
    margin-bottom:30px;
    width:370px;
    display: flex;
    flex-direction: column;
}
.middleTitle{
    margin-left: 10px;
    margin-top:4px;
    color: #666666;
    font-family: '微软雅黑 Bold', '微软雅黑', '微软雅黑', sans-serif;
    font-weight: 700;
    font-size: 16px;
}
.middleContent{
    margin-top: 10px;
    margin-left:10px;
    word-wrap: break-word;
    max-height:300px;
    overflow: auto;
    font-family: '微软雅黑', sans-serif;
    font-weight: 400;
    color: #999999;
    font-size: 14px;
}
.bottomContainer{
    height:60px;
    width:100%;
    border-top: 1px solid rgb(228, 228, 228);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.cancelButton{
    font-family: '微软雅黑 Bold', '微软雅黑', '微软雅黑', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: #0079FE;
    border-radius:3px;
    border:1px solid #0079FE;
    width:80px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:10px;
    cursor: pointer;
}
.confirmButton{
    font-family: '微软雅黑 Bold', '微软雅黑', '微软雅黑', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    background-color: #0079FE;
    color:white;
    border-radius:3px;
    width:80px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:10px;
    cursor: pointer;
}