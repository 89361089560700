.background{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-width:1100px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(153, 153, 153, 1);
    background-image: url("./loginBgImg.jpg");
    background-position: right top;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-origin: border-box;
}
.bgImg{
    display: none;
    height:100%;
    width:100%;
    position: fixed;
    top:0px;
    left: 0px;
    object-fit:cover;
}
.contentContainer{
    display: flex;
    /* margin-top:138px;*/
    background-color: rgba(0, 0, 0, 0.4980392156862745);
    width:100vw;
    min-width:1100px;
    height:300px;
    flex-direction: column;
    align-items:center;
    z-index: 100;

}
.contentTop{
    display: flex;
    width:1100px;
    min-width:1100px;
    justify-content: flex-start;
    margin-top:40px;
}
.topIcon{
    width:100px;
    height:100px;
    margin-left:226px;
}
.topTextContainer{
    height:96px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    margin-left:10px;
}
.BigTitle{
    font-size: 44px;
    font-weight: 700;
    color: #FFFFFF;
    text-align: left;
}
.smallTitle{
    font-size: 33px;
    color: #FFFFFF;
    text-align: left;
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
}
.contentMiddle{
    display: flex;
    justify-content:flex-start;
    width:1100px;
}
.userNameContainer{
    width:300px;
    height:50px;
    background-color:white;
    margin-top:28px;
    margin-left:246px;
    color: #CCCCCC;
    display: flex;
    align-items: center;
}
.userIcon{
    width:26px;
    height:26px;
    margin-left: 7px;
    margin-right:7px;
}
.passwordContainer{
    width:300px;
    height:50px;
    background-color:white;
    margin-top:28px;
    margin-left:18px;
    color: #CCCCCC;
    display: flex;
    align-items: center;
}
.userInput{
    width:210px;
    height:42px;
    padding: 4px;
    border: none;
}
.userInput:focus{
    border:none;
    outline:none;
}
.passwordIcon{
    width:20px;
    height:20px;
    margin-left: 10px;
    margin-right:10px;
}
.confirmButton{
    margin-top:40px;
    width: 50px;
    height: 50px;
    color: white;
    cursor: pointer;
}

.contentBottom{
    width:1100px;
    display: flex;
}
.contentTipContainer{
    margin-left:245px;
    color: #FFA847;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tipIcon{
    width: 20px;
    height:20px;
    margin-right:8px;
}

.footerContainer{
    position: fixed;
    bottom: 6vh;
}