.background{
    width: 100%;
    height:64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 4;
}
.footerDes{
    font-family: '微软雅黑', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #CCCCCC;
    line-height: 24px;
}
