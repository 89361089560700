body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: rgba(240, 242, 245, 1);
  display: flex;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
input{
  border: 1px solid rgba(194,194,194,1);
  border-radius: 4px;
}
input:focus{
  border-color: #165DFF;
  outline: #165DFF;
}
textarea{
  border: 1px solid rgba(194,194,194,1);
  border-radius: 4px;
}
textarea:focus{
  border-color: #165DFF;
  outline: #165DFF;
}